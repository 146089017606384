<template>
    <!-- ============================ Hero Banner  Start================================== -->
    <div class="" style="background:#1CA4D4; min-height:100vh">

        <div class="container">
            <div class="row align-items-center">

                <div class="col-lg-4 col-md-12 col-sm-12"></div>
                <div class="col-lg-4 col-md-12 col-sm-12 mt-2" >

                     <router-link  to="/home">

                        <base-image style="max-width:150px"
                            imgName="white_logo.png"
                            imgGeo="logo"
                            imgAlt="logo"
                            class="mt-4 mb-2 center-image"
                         >
                         </base-image>

                     </router-link>

                     <div class="mb-4 text-white text-center">
                         Welcome to the future of career opportunities
                    </div>


                    <!-- Single Wrap -->
                    <create-auth-sign-up >
                    </create-auth-sign-up>
                    <!-- Single Wrap End -->

                    <div class="text-center mb-3">
                            <router-link to="login">
                                <small class="auth-extra-link">
                                  Already have an account ? Sign in
                                </small>
                            </router-link>
                   </div>

                </div>

                <div class="col-lg-4 col-md-12 col-sm-12"></div>

            </div>
        </div>
    </div>
    <!-- ============================ Hero Banner End ================================== -->

</template>

<script>
import { ref } from 'vue';
import { useStore } from 'vuex';
// @ is an alias to /src
import CreateAuthSignUp from '@/components/forms/auth/CreateAuthSignUp.vue';

export default {
  name: 'AuthSignUp',
  components: {
      CreateAuthSignUp
  },
  setup(){
      // get the global vuex store values
      const store = useStore();

      // use the dispatch function to call an action that calls a mutation
      store.dispatch("globals/executeSetNavMenu",false);
      store.dispatch("globals/executeSetFooterMenu",false);
      // 
      // let uri = window.location.search.substring(1);
      // let params = new URLSearchParams(uri);
      // const platform = ref(params.get("platform")?params.get("platform"):"");
      //
      // let loginLink = platform.value ? '/login?platform='+platform.value : '/login';



      const isAccountCreated = ref(store.getters["auth/getAccountCreated"]);

      return {
          isAccountCreated
      }


  }

};
</script>

<style scoped>

.employer-brands ul li{
    list-style-type:none;
    display:inline;
}

.center-image{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

</style>
