<template>
  <form  @submit.prevent="createAccount" v-if="!isAccountCreated">
    <div class="_dashboard_content"  v-if="tokenData">
        <div class="_dashboard_content_header">
            <div class="_dashboard__header_flex text-center">
                <h4>
                    <i class="bi bi-power"></i>
                    Create a secure account
                </h4>
                <div class="mt-1 mb-1">
                    By signing up you agree to Sabenzi's Data Policy and Terms and conditions
                </div>
            </div>
        </div>

        <div class="_dashboard_content_body">
            <div class="row">
                <div >

                    <div class="col-xl-12 col-lg-12 col-12">
                        <div class="form-group">
                            <label :class="fv.name.msg?'text-danger':''" for="name">
                                {{ fv.accountType.val == 'organization'?'Organization name':'Full name' }}
                                <span v-if="fv.name.msg" > | {{fv.name.msg}}</span>
                            </label>
                            <input type="text"
                                   :placeholder="fv.accountType.val == 'organization'?'Enter organization name':'Enter full name'"
                                   required v-model.trim="fv.name.val"
                                   @keyup="validate.validateFormData(fv,'name',true)"
                                   class="form-control with-light" id="name">
                        </div>
                    </div>

                    <div class="col-xl-12 col-lg-12 col-12">
                        <div class="form-group">
                            <label :class="fv.email.msg?'text-danger':''" for="email">
                                Email
                                <span v-if="fv.email.msg" > | {{fv.email.msg}}</span>
                            </label>
                            <input type="text" placeholder="Enter email"
                                   required v-model.trim="fv.email.val"
                                   @keyup="validate.validateFormData(fv,'email',true)"
                                   class="form-control with-light" id="email">
                        </div>
                    </div>

                    <div class="col-xl-12 col-lg-12 col-12">
                        <div class="form-group">
                            <label :class="fv.password.msg?'text-danger':''" for="password">
                                Password
                                <span v-if="fv.password.msg" > | {{fv.password.msg}}</span>
                            </label>
                            <div class="input-group">
                                <input class="form-control with-light" required id="password"
                                       :type="fv.password.showPassword?'text':'password'" v-model="fv.password.val"
                                       :class="fv.password.msg?'border-danger':''"
                                       @keyup="validate.validateFormData(fv,'password',true)"
                                       placeholder="Enter password">
                                <div class="input-group-prepend rounded-right cursor-pointer">
                                    <span class="input-group-text b-0 rounded-right"
                                          id="basic-addon1"
                                          @click="togglePasswordVisibility">
                                        <i class="bi " :class="fv.password.showPassword?'bi-eye-slash-fill':'bi-eye-fill'"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-12 col-lg-12 col-12">
                        <div class="form-group">
                            <label v-if="fv.captcha.msg" class="form-label text-danger" >
                                {{fv.captcha.msg}}
                            </label>
                            <base-captcha :key="captcha" :captchaVal="captcha" ></base-captcha>
                            <input  required v-model="fv.captcha.val"  min="7" max="7"
                                    @keyup="validate.validateFormData(fv,'captcha',true)"
                                    type="text" placeholder="Enter the text above" class="form-control with-light">
                        </div>
                    </div>

                    <div class="col-12" v-if="fv.reqFeedback.status">

                        <base-list-tag class="mt-1" :tagColor="fv.reqFeedback.val" >{{fv.reqFeedback.msg}}</base-list-tag>
                    </div>

                    <div class="col-xl-12 col-lg-12 col-12" v-if="fv.showSubmit.val">
                        <button type="submit" class="btn btn-save col-12">Create account</button>
                    </div>
                    <base-spinner class="ml-3" v-else></base-spinner>

                </div>

            </div>
        </div>
    </div>
    <base-card-response   v-else>

        <template #header></template>
        <template #default>
            <p>
                {{reqError}}
            </p>
        </template>

    </base-card-response>

  </form>

  <base-card-response  cardType="success" class="mt-1" v-if="isAccountCreated">

      <template #header>Account successfully created. </template>
      <template #default>
          <p>
              Kindly check your email for a verification link.
              If you are not automatically redirected.
          </p>
          <p><a :href="platformLink"> <u style="color:#fff">Click this link </u></a></p>
      </template>

  </base-card-response>
</template>


<script>
import { ref,reactive,inject } from 'vue'
import { useStore } from 'vuex';
// import { useRouter } from 'vue-router';
import validators from '@/hooks/validation'

export default{

  name: 'CreateAuthSignUp',
  setup() {
    // get the global vuex store objects
    const store = useStore();
    // const router = useRouter();

    // get the validators
    const validate = validators();

    // get the axios object
    const axios = inject('axios');  // inject axios

    //get the urls
    const coreUrl = inject('coreUrl');

    // initiate token, csrf_token and captcha variables with blank values
    let token = "";
    let csrf_token = "";
    const captcha = ref("");

    const tokenData  = ref(false);
    const reqError  = ref("Loading sign up form....");



    // when the vue module is created, get the token value, using the try catch

            // use axios to get the data
            axios.get(coreUrl.backendUrl+'get_csrf_token',{withCredentials: true}).then((response) =>{
                // return the full token to the token variable
                token = response.data.data;
                tokenData.value = true;

                // make the first 43 characters, the csrf token
                csrf_token = token.substring(0,43)

                // make the last 5 characters, the captcha
                captcha.value = token.substring(43,48)


            }).catch((error)=>{
                // if an error occours, do this
                tokenData.value = false;
                const errorMsg = "Sign up form can't be loaded at this time. Try again";
                reqError.value = error.response.data.message.req_msg?errorMsg:errorMsg;
            })




     // create variables for the values to be submitted
    const fv = reactive({
        name : {status:false, val:"", msg:false, validate:["required",
                                                                 "safeSpaceInput",
                                                                 {option:"textMin",val:3},
                                                                 {option:"textMax",val:120}
                                                                ]},
        email : {status:false, val:"", msg:false, validate:["required","email"]},
        accountType : {status:false, val:"individual", msg:false, validate:["required"]},
        redirectLink : {status:false, val:"dashboard", msg:false, validate:["required"]},
        password : {status:false, val:"", msg:false, showPassword: false, validate:["required",
                                                                                      {option:"textMin",val:10},
                                                                                      {option:"textMax",val:64}
                                                                                     ]},
        captcha : {status:false, val:"", msg:false, validate:["required","alphaNumeric"]},
        reqFeedback : {status:false, val:"", msg:false},
        showSubmit : {status:false, val:true}
    })


    // toggle password visibility
    function togglePasswordVisibility(){
        fv.password.showPassword = !fv.password.showPassword;
    }

    // get the current status of the created account
    const isAccountCreated = ref(store.getters["auth/getAuthAuthentication"].isAccountCreated);

    // create the account using async
    async function createAccount(){

        // end the action of making a submission, and hide loader
        fv.showSubmit.val = false;

        // load the form data , and append all the necessary fields
        let formData = new FormData();
        formData.append("fv_name", fv.name.val);
        formData.append("fv_email", fv.email.val);
        formData.append("fv_password", fv.password.val);
        formData.append("fv_terms","read_accepted");
        formData.append("fv_csrf_token",csrf_token+fv.captcha.val);
        formData.append("fv_account_type", fv.accountType.val);
        formData.append("fv_account_credentials_source", "sabenzi");
        formData.append("fv_host_root_url", coreUrl.jobsUrl);


        // post the data using axios
        try{
            const response = await axios.post(
                coreUrl.backendUrl+'create_basic_account',
                formData,
                {
                    withCredentials: true,
                    headers:{
                       "Content-Type": "multipart/form-data"
                    }
                }
            );

            // If successful, get the resppnse data
            const responseData = response.data.data;

            console.log(response.data)

            if(response.data.status){
                const authToken = responseData.token;
                // update the vuex authentication data
                const authAuthentication = {
                                isAuthenticated : true,
                                isAccountCreated : true,
                            }


                // update the vuex credentials data
                const authCredentials = {
                    accountName: fv.name.val,
                    accountEmail: fv.email.val,
                    accountType : fv.accountType.val,
                    accountId : responseData.account_id
                }

                // update the vuex permissions data
                const authPermissions = responseData.permissions

                // execute and update state for  token,authentication,credentials & permissions
                store.dispatch("auth/executeSetAuthToken",authToken );
                store.dispatch("auth/executeSetAuthAuthentication",authAuthentication);
                store.dispatch("auth/executeSetAuthCredentials",authCredentials);
                store.dispatch("auth/executeSetAuthPermissions",authPermissions);

                // update is account created status
                isAccountCreated.value = true;

                // end the action of making a submission, and hide loader
                fv.showSubmit.val = true;

                // router.push(fv.redirectLink.val);
                window.location.replace(coreUrl.jobsUrl+fv.redirectLink.val);
            }else{
                // redo the captcha sequence
                token =  response.data.data?response.data.data:"bad";
                csrf_token =  response.data.data?token.substring(0,43):"bad";
                captcha.value =  response.data.data?token.substring(43,48):"bad";

                // load the error to the appropriate field
                const fv_msg = response.data.message.fv_msg;
                const req_msg = response.data.message.req_msg;

                for(const key in fv_msg){
                    if(fv[key]){
                        fv[key]["status"] = false;
                        fv[key]["msg"] = fv_msg[key];
                    }
                }


                fv.reqFeedback.status = true;
                fv.reqFeedback.msg = req_msg;
                fv.reqFeedback.val = "danger";

                // end the action of making a submission, and hide loader
                fv.showSubmit.val = true;
            }



        }catch(error){


            // redo the captcha sequence
            token =  error.response.data.data?error.response.data.data:"bad";
            csrf_token =  error.response.data.data?token.substring(0,43):"bad";
            captcha.value =  error.response.data.data?token.substring(43,48):"bad";

            // load the error to the appropriate field
            const fv_msg = error.response.data.message.fv_msg;
            const req_msg = error.response.data.message.req_msg;

            for(const key in fv_msg){
                if(fv[key]){
                    fv[key]["status"] = false;
                    fv[key]["msg"] = fv_msg[key];
                }
            }


            fv.reqFeedback.status = true;
            fv.reqFeedback.msg = req_msg;
            fv.reqFeedback.val = "danger";

            // end the action of making a submission, and hide loader
            fv.showSubmit.val = true;

        }

    }

    return {
        fv,
        validate,
        tokenData,
        reqError,
        togglePasswordVisibility,
        createAccount,
        captcha,
        isAccountCreated
     }
  }
}
</script>
